<script setup lang="ts" generic="T">
import type { ResourceCollection } from '@app/types/shared'
import type { Taskable } from '@app/types/task'
import DataTable from '../ui/table/DataTable.vue'
import FilterSection from '../ui/table/FilterSection.vue'
import ResetFilterButton from '../ui/table/filters/ResetFilterButton.vue'
import { route } from 'ziggy-js'
import { computed } from 'vue'
import TaskDescription from './TaskDescription.vue'
import TaskAction from './TaskAction.vue'
import DropdownFilter from '../ui/table/filters/DropdownFilter.vue'

defineProps<{ tasks: ResourceCollection<Taskable<T>> }>()

const columns = computed(() => [
    {
        name: 'description',
        header: 'attributes.description',
    },
    {
        type: 'multi-line-date',
        header: 'attributes.created_at',
        properties: { name: 'created_at' },
    },
    {
        name: 'action',
        header: 'actions.header',
    },
])

const statusItems = [
    {
        name: 'tasks.statuses.todo',
        slug: 'todo',
    },
    {
        name: 'tasks.statuses.completed',
        slug: 'completed',
    },
]
</script>

<template>
    <DataTable :items="tasks" :columns only="tasks">
        <template #filter>
            <FilterSection>
                <DropdownFilter :items="statusItems" :translate-db="false" by="slug" property-name="name" slug="status" />
                <ResetFilterButton :href="route('user.tasks.index')" />
            </FilterSection>
        </template>

        <template #column.description="{ item }">
            <TaskDescription :task="item" />
        </template>

        <template #column.action="{ item }">
            <TaskAction :task="item" />
        </template>
    </DataTable>
</template>
