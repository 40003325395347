<script setup lang="ts">
import Button from '@app/components/ui/button/Button.vue'
import ActionMessage from '@app/components/ui/form/ActionMessage.vue'
import FieldError from '@app/components/ui/form/FieldError.vue'
import Form from '@app/components/ui/form/Form.vue'
import FormActions from '@app/components/ui/form/FormActions.vue'
import FormField from '@app/components/ui/form/FormField.vue'
import FormSection from '@app/components/ui/form/FormSection.vue'
import Input from '@app/components/ui/form/Input.vue'
import { useAvatar } from '@app/composables/user/useAvatar'
import UserSettings from '@app/layouts/UserSettings.vue'
import type { User } from '@app/types/shared'
import { router, useForm } from '@inertiajs/vue3'
import { ref } from 'vue'
import { route } from 'ziggy-js'

const { getUserAvatar } = useAvatar()

const props = defineProps<{ user: User }>()

const form = useForm<{
    _method: 'PUT'
    first_name: string
    last_name: string
    email: string
    photo: File | null
}>({
    _method: 'PUT',
    first_name: props.user.first_name,
    last_name: props.user.last_name,
    email: props.user.email,
    photo: null,
})

const photoPreview = ref<string | ArrayBuffer | null>()
const photoInput = ref<HTMLInputElement>()

function updateProfileInformation() {
    if (photoInput.value?.files) {
        form.photo = photoInput.value.files[0]
    }

    form.post(route('user.profile.update'), {
        preserveScroll: true,
        onSuccess: () => clearPhotoFileInput(),
    })
}

function selectNewPhoto() {
    photoInput.value?.click()
}

function updatePhotoPreview() {
    if (!photoInput.value?.files) {
        return
    }

    const photo = photoInput.value.files[0]

    const reader = new FileReader()

    reader.onload = (e) => {
        photoPreview.value = e.target?.result
    }

    reader.readAsDataURL(photo)
}

function deletePhoto() {
    router.delete(route('current-user-photo.destroy'), {
        preserveScroll: true,
        onSuccess: () => {
            photoPreview.value = null
            clearPhotoFileInput()
        },
    })
}

function clearPhotoFileInput() {
    if (photoInput.value?.value) {
        photoInput.value.value = ''
    }
}
</script>

<template>
    <UserSettings>
        <div class="flex w-full flex-col gap-x-8 gap-y-10 px-4 py-16 sm:flex-row sm:px-6 lg:px-8">
            <div class="flex w-full flex-col sm:w-1/2">
                <h2 class="text-lg font-semibold leading-7">
                    {{ $t('user.profile.title') }}
                </h2>
                <p class="mt-1 text-sm leading-6 text-zinc-400">{{ $t('user.profile.description') }}</p>
            </div>
            <Form :form="form" @submit="updateProfileInformation" class="w-full max-w-xl space-y-8">
                <FormSection>
                    <!-- Profile Photo -->
                    <div class="col-span-12 mb-4">
                        <!-- Profile Photo File Input -->
                        <input ref="photoInput" type="file" class="hidden" @change="updatePhotoPreview" />

                        <!-- Current Profile Photo -->
                        <div v-if="!photoPreview">
                            <img :src="getUserAvatar(user)" :alt="user.full_name" class="h-20 w-20 rounded-full object-cover" />
                        </div>

                        <!-- New Profile Photo Preview -->
                        <div v-if="photoPreview">
                            <span class="block h-20 w-20 rounded-full bg-cover bg-center bg-no-repeat" :style="`background-image: url('${photoPreview}');`" />
                        </div>

                        <div class="mt-4 flex gap-x-2">
                            <Button @click.prevent="selectNewPhoto" type="button" color="white">
                                {{ $t('user.profile.photo.new_photo') }}
                            </Button>

                            <Button v-if="user.profile_photo_path" @click.prevent="deletePhoto" type="button" color="white">
                                {{ $t('user.profile.photo.remove_photo') }}
                            </Button>
                        </div>

                        <FieldError :message="form.errors.photo" />
                    </div>

                    <FormField prop="first_name" :label="$t('attributes.first_name')" class="col-span-12">
                        <Input id="first_name" v-model="form.first_name" autocomplete="given-name" />
                    </FormField>

                    <FormField prop="last_name" :label="$t('attributes.last_name')" class="col-span-12">
                        <Input id="last_name" v-model="form.last_name" autocomplete="family-name" />
                    </FormField>

                    <FormField prop="email" :label="$t('attributes.email')" class="col-span-12">
                        <Input id="email" v-model="form.email" autocomplete="email" />
                    </FormField>
                </FormSection>

                <FormActions class="!justify-start">
                    <Button :disabled="form.processing">{{ $t('buttons.save') }}</Button>
                    <ActionMessage :on="form.recentlySuccessful">{{ $t('buttons.saved') }}</ActionMessage>
                </FormActions>
            </Form>
        </div>
    </UserSettings>
</template>
