<script setup lang="ts">
import ContractSignForm from '@app/components/contracts/sign/ContractSignForm.vue'
import PayrollTaxCreditAlert from '@app/components/contracts/sign/PayrollTaxCreditAlert.vue'
import PayrollTaxCreditFormDownload from '@app/components/contracts/sign/PayrollTaxCreditFormDownload.vue'
import Button from '@app/components/ui/button/Button.vue'
import FileInputButton from '@app/components/ui/form/file/FileInputButton.vue'
import FilePreview from '@app/components/ui/form/file/FilePreview.vue'
import Form from '@app/components/ui/form/Form.vue'
import FormActions from '@app/components/ui/form/FormActions.vue'
import FormField from '@app/components/ui/form/FormField.vue'
import FormSection from '@app/components/ui/form/FormSection.vue'
import Input from '@app/components/ui/form/Input.vue'
import Label from '@app/components/ui/form/Label.vue'
import Radio from '@app/components/ui/form/Radio.vue'
import type { FormStep } from '@app/components/ui/multiStepForm/types'
import { useFormWizard } from '@app/composables/form/useFormWizard'
import Guest from '@app/layouts/Guest.vue'
import { useForm } from '@inertiajs/vue3'
import { watch } from 'vue'

defineOptions({
    layout: Guest,
})

type FinancialInformationModel = {
    account_holder_name: string | null
    iban: string | null
    payroll_tax_credit: number | null
    bsn: string | null
    payroll_tax_credit_file: string | null
}

const props = defineProps<{
    steps: FormStep[]
    model: FinancialInformationModel
}>()

const { previousStep, navigateToPreviousStep } = useFormWizard(props.steps)

const form = useForm({
    account_holder_name: props.model.account_holder_name,
    iban: props.model.iban,
    payroll_tax_credit: props.model.payroll_tax_credit || 0,
    bsn: props.model.bsn,
    payroll_tax_credit_file: props.model.payroll_tax_credit_file,
})

watch(
    () => form.payroll_tax_credit,
    () => {
        if (!form.payroll_tax_credit) {
            form.payroll_tax_credit_file = null
        }
    }
)
</script>

<template>
    <ContractSignForm :steps="steps">
        <Form :form="form" @submit="form.post('')">
            <FormSection>
                <FormField class="col-span-12 sm:col-span-6" :required="true" prop="account_holder_name" :label="$t('attributes.account_holder_name')">
                    <Input id="account_holder_name" v-model="form.account_holder_name" type="text" />
                </FormField>

                <FormField class="col-span-12 sm:col-span-6" :required="true" prop="iban" :label="$t('attributes.iban')">
                    <Input id="iban" v-model="form.iban" type="text" placeholder="NL00 0000 0000 0000 00" />
                </FormField>

                <FormField class="col-span-12 sm:col-span-6" :required="true" prop="bsn" :label="$t('attributes.bsn')">
                    <Input id="bsn" v-model="form.bsn" type="text" placeholder="123456789" />
                </FormField>

                <div class="col-span-12">
                    <div class="mt-1 flex flex-col gap-3">
                        <Label>{{ $t('attributes.payroll_tax_credit') }}? *</Label>
                        <PayrollTaxCreditAlert />
                        <Radio v-model="form.payroll_tax_credit" :value="0">{{ $t('contract.payroll_tax_credit.no') }}</Radio>
                        <Radio v-model="form.payroll_tax_credit" :value="1">{{ $t('contract.payroll_tax_credit.yes') }}</Radio>
                    </div>

                    <div v-if="form.payroll_tax_credit" class="mt-2 max-w-lg">
                        <p class="mt-1 text-sm text-gray-600">
                            {{ $t('contract.payroll_tax_credit.hint') }}
                        </p>

                        <PayrollTaxCreditFormDownload class="mt-1 block" />

                        <FormField prop="payroll_tax_credit_file" class="mt-4 max-w-sm">
                            <FileInputButton v-model="form.payroll_tax_credit_file" accept=".pdf" />
                            <FilePreview v-model="form.payroll_tax_credit_file" class="mt-1" />
                        </FormField>
                    </div>
                </div>
            </FormSection>

            <FormActions>
                <Button v-if="previousStep" color="white" type="button" @click="navigateToPreviousStep">{{ $t('buttons.back') }}</Button>
                <Button :disabled="form.processing">{{ $t('buttons.save') }}</Button>
            </FormActions>
        </Form>
    </ContractSignForm>
</template>
