<script setup lang="ts">
import ContentTabs from '@app/components/layout/ContentTabs.vue'
import { useNavigation } from '@app/composables/layout/useNavigation'
import { usePage } from '@inertiajs/vue3'
import { computed } from 'vue'
import { route } from 'ziggy-js'

const { currentRoute } = useNavigation()

const accountSettingsTabs = computed(() => [
    {
        name: 'navigation.account_management.profile',
        href: route('user.profile.show'),
        active: currentRoute.value === 'user.profile.show',
    },
    {
        name: 'navigation.account_management.security',
        href: route('user.settings.show'),
        active: currentRoute.value === 'user.settings.show',
    },
    {
        name: 'tasks.name',
        href: route('user.tasks.index', { status: 'todo' }),
        active: currentRoute.value === 'user.tasks.index',
        count: usePage().props.userTasksCount || 0,
    },
])
</script>

<template>
    <ContentTabs :items="accountSettingsTabs" bottomBorder />

    <slot></slot>
</template>
