import { useTemplateRef, type ModelRef } from 'vue'

export type FileInputModel = File | string | null

export type FileInputProps = {
    limit?: number
    fileTypes?: string[]
    accept?: string
}

export function useFileInput(model: ModelRef<unknown>) {
    const fileInput = useTemplateRef<HTMLInputElement>('input:file')

    function selectFile() {
        model.value = fileInput.value?.files?.[0] || null
    }

    return {
        fileInput,
        selectFile,
    }
}
