<script setup lang="ts">
import type { FileInputModel } from '@app/composables/form/useFileInput'
import { computed } from 'vue'

const model = defineModel<FileInputModel>()

const fileName = computed(() => {
    if (!model.value) {
        return undefined
    }

    if (model.value instanceof File) {
        return model.value.name
    }

    if (typeof model.value === 'string') {
        const url = new URL(model.value)

        const pathParts = url.pathname.split('/')

        return pathParts[pathParts.length - 1]
    }
})
</script>

<template>
    <div v-if="fileName" class="flex items-center gap-2 text-gray-500">
        <span class="text-ellipsis text-sm font-normal leading-5">
            {{ fileName }}
        </span>

        <button @click="model = null" class="text-lg">&times;</button>
    </div>
</template>
