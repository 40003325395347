<script setup>
import { applyFilter } from '@app/utils/filter'
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'
import { trans } from 'laravel-vue-i18n'
import { computed, inject, ref, watch } from 'vue'

const options = [10, 25, 50, 100]
const params = route().params
const selectedItem = ref(params.per_page ? parseInt(params.per_page) : 10)

const items = inject('datatable:items')
const only = inject('datatable:only')

watch(selectedItem, (newValue) => {
    applyFilter({ per_page: newValue }, { only: [only] })
})

const page = ref(items.value.current_page ?? 1)
const getResultsPerPage = computed(() => {
    return trans('pagination.results', {
        from: 1 + (page.value - 1) * selectedItem.value,
        to: page.value * selectedItem.value > items.value.total ? items.value.total : page.value * selectedItem.value,
        total: items.value.total,
    })
})
</script>

<template>
    <div v-if="items.total" class="flex items-center text-xs font-normal leading-4 text-gray-500">
        <Listbox as="div" v-model="selectedItem" class="w-18">
            <div class="relative">
                <ListboxButton
                    class="relative w-full cursor-default rounded-md border border-gray-100 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500"
                >
                    <span class="ml-1 block truncate">
                        {{ selectedItem }}
                    </span>
                    <span class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                        <ChevronUpDownIcon class="h-5 w-5 text-gray-700" aria-hidden="true" />
                    </span>
                </ListboxButton>

                <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                    <ListboxOptions
                        class="absolute z-10 mt-1 max-h-56 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-400 focus:outline-none sm:text-sm"
                    >
                        <ListboxOption as="template" v-for="item in options" :value="item" v-slot="{ active, selected }">
                            <li :class="[active ? 'bg-primary-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                <div class="flex items-center">
                                    <span :class="[selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate']">{{ item }}</span>
                                </div>
                                <span
                                    v-if="selected"
                                    :class="[active ? 'text-white' : 'text-primary-600', 'absolute inset-y-0 right-0 flex items-center pr-4']"
                                >
                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                </span>
                            </li>
                        </ListboxOption>
                    </ListboxOptions>
                </transition>
            </div>
        </Listbox>
        <span class="pl-2">{{ getResultsPerPage }} </span>
    </div>
</template>
