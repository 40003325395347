<script setup lang="ts">
import FileInputButton from '@app/components/ui/form/file/FileInputButton.vue'
import Form from '@app/components/ui/form/Form.vue'
import FormField from '@app/components/ui/form/FormField.vue'
import type { User } from '@app/types/shared'
import type { Taskable } from '@app/types/task'
import { useForm } from '@inertiajs/vue3'
import { route } from 'ziggy-js'

const props = defineProps<{ task: Taskable<User> }>()

const form = useForm({
    payroll_tax_credit_file: null,
})

function handleFileSelect() {
    form.post(route('task.complete', props.task.id), { only: ['tasks', 'userTasksCount'] })
}
</script>

<template>
    <Form :form="form">
        <FormField prop="payroll_tax_credit_file">
            <FileInputButton v-model="form.payroll_tax_credit_file" @change="handleFileSelect" accept=".pdf" :file-types="['pdf']" />
        </FormField>
    </Form>
</template>
