<script setup lang="ts">
import { type FileInputModel, type FileInputProps, useFileInput } from '@app/composables/form/useFileInput'
import { watch } from 'vue'

const model = defineModel<FileInputModel>()

defineProps<FileInputProps>()

const { selectFile } = useFileInput(model)

const emit = defineEmits(['change'])

watch(model, () => {
    emit('change', model.value)
})
</script>

<template>
    <label
        class="cursor-pointer gap-2 rounded-md bg-primary-600 px-4 py-2 text-sm leading-5 text-white transition-colors hover:bg-primary-700 focus:ring-primary-600 disabled:bg-primary-400"
    >
        <slot>{{ $t('form.file.hint_select') }}</slot>
        <input type="file" class="hidden" @change="selectFile" ref="input:file" :accept="accept" />
    </label>
</template>
