<script setup>
import { useLocale } from '@app/composables/useLocale'
import { useUserCan } from '@app/composables/useUserCan'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'
import { Link } from '@inertiajs/vue3'
import { computed } from 'vue'
import Tab from '../ui/tab/Tab.vue'

const props = defineProps({
    items: Array,
    bottomBorder: {
        type: Boolean,
        default: false,
    },
})

const { can } = useUserCan()
const { translate } = useLocale()

const activeTabName = computed(() => {
    for (const item of props.items) {
        if (item.active) {
            return item.name
        }
    }
})

const visibleItems = computed(() => props.items?.filter((item) => (item.permission ? can(item.permission) : true)))
</script>

<template>
    <div class="hidden w-full pb-4 sm:block">
        <div :class="[bottomBorder ? 'border-b border-gray-100' : '', 'w-full']">
            <div class="-mb-px flex w-4/6 justify-start gap-4" aria-label="Tabs" key="secondarynavigation">
                <Tab
                    v-for="item in visibleItems"
                    :id="item.id ? `tab-${item.id}` : null"
                    :name="item.name"
                    :count="item.count"
                    :href="item.href"
                    :active="item.active"
                />
            </div>
        </div>
    </div>
    <Popover class="relative block pb-4 sm:hidden">
        <PopoverButton
            class="inline-flex w-full items-center justify-between space-x-8 border-b-2 border-primary-600 p-4 text-base font-medium leading-6 text-primary-600 focus:border-b-2 focus:border-primary-500 focus-visible:outline-none"
        >
            <span> {{ translate(activeTabName) }} </span>
            <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
        </PopoverButton>
        <transition
            enter-active-class="transition ease-out duration-200"
            enter-from-class="opacity-0 translate-y-1"
            enter-to-class="opacity-100 translate-y-0"
            leave-active-class="transition ease-in duration-150"
            leave-from-class="opacity-100 translate-y-0"
            leave-to-class="opacity-0 translate-y-1"
        >
            <PopoverPanel class="absolute z-10 flex w-full">
                <div class="w-full flex-auto rounded-md bg-white p-4 text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                    <div v-for="item in visibleItems" :key="item.name" class="relative rounded-lg p-4 hover:bg-gray-50">
                        <Link :href="item.href" :class="[item.active ? 'font-semibold text-primary-600' : 'font-medium text-gray-700']">
                            {{ translate(item.name) }}
                            <span class="absolute inset-0" />
                        </Link>
                    </div>
                </div>
            </PopoverPanel>
        </transition>
    </Popover>
</template>
